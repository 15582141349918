import React from 'react';

export const scheduleSanJose = [
  {
    id: 1,
    title: 'Registration',
    time: '9:30 AM',
  },
  {
    id: 2,
    title: 'Welcome',
    time: '10:00 AM',
  },
  {
    id: 3,
    title: 'Session/Panel: Steps to Providing a Killer Customer Experience',
    time: '10:15 AM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 4,
    title: 'Sessions: Shopmonkey Product Roadmap; Shopmonkey Product Demo',
    time: '11:00 AM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 5,
    title: 'Lunch; Complimentary Shop Assessments',
    time: '11:45 AM',
  },
  {
    id: 6,
    title: 'Workshop: How to Build a Strategic Marketing Plan for Your Shop',
    time: '01:15 PM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 7,
    title:
      'Session: How to Maximize Efficiency and Revenue with Shopmonkey Integrated Payments',
    time: '02:15 PM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 8,
    title: 'Break - Ice Cream Social & Beer; Complimentary Shop Assessments',
    time: '02:45 PM',
  },
  {
    id: 9,
    title: 'Session: Three Ways to Supercharge Your Operational Efficiency',
    time: '03:30 PM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 10,
    title: 'Closing Remarks',
    time: '04:15 PM',
  },
];

export const scheduleLosAngeles = [
  {
    id: 1,
    title: 'Registration',
    time: '9:30 AM',
  },
  {
    id: 2,
    title: 'Welcome',
    time: '10:00 AM',
  },
  {
    id: 3,
    title: 'Session/Panel: Steps to Providing a Killer Customer Experience',
    time: '10:15 AM',
    body: (
      <>
        <p>
          Providing a professional, seamless customer experience is important to
          building the kind of loyalty that will keep customers coming back to
          your shop. Hear directly from real shop owners who are providing
          killer customer experiences, plus learn from Shopmonkey’s Head of
          Customer Support about the foundations for creating a great customer
          experience.
        </p>
        <p className="sub-title">In this session you will learn:</p>
        <ul className="bullet-ul">
          <li>How real shops approach their customer service strategy</li>
          <li>What’s at the core of a positive customer experience</li>
          <li>Tips for taking your customers’ experience to the next level</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    title: 'Sessions: Shopmonkey Product Roadmap; Shopmonkey Product Demo',
    time: '11:00 AM',
    body: <p>Details coming soon</p>,
  },
  {
    id: 5,
    title: 'Lunch; Complimentary Shop Assessments',
    time: '11:45 AM',
  },
  {
    id: 6,
    title:
      'Workshop: How to Kickstart a Strategic Marketing Plan for Your Shop',
    time: '01:15 PM',
    body: (
      <>
        <p>
          82% of Americans report that they actively use social media every day
          and that percentage is only increasing as time goes on. Learn proven
          strategies and best practices from national social media marketing
          experts Jared Guynes and Jessica Sapp and get equipped with easy,
          actionable tactics you can implement in your shop today to kickstart a
          strategic marketing plan.
        </p>
        <p className="sub-title">In this session you will learn:</p>
        <ul className="bullet-ul">
          <li>
            How the pandemic has increased consumer reliance and usage of social
            media
          </li>
          <li>
            How consistent, simple storytelling can propel your business and
            increase your searchability online
          </li>
          <li>
            Proven strategies using Facebook & Instagram to generate high
            quality leads for your shop
          </li>
        </ul>
        <p className="sub-title">Speakers:</p>
        <p className="mb-2">
          <strong>Jared Guynes</strong> is a seasoned social media specialist
          who likes to believe that anything in life can be EPIC. Jared is an
          eccentric, outgoing person who holds not one, but three Guinness World
          Records for events that he has created & executed, in addition to 14
          years of experience in all facets of marketing & promotions for local
          to large businesses. Jared regularly travels the globe with his
          partner Jessica providing digital marketing consulting and has worked
          with more than 36 shops from coast to coast.
        </p>
        <p>
          <strong>Jessica Sapp</strong> has been engrossed in the world of media
          her entire life. After being a member of the video tech team in high
          school and graduating salutatorian early at age 16, she went on to
          study Digital Media at Texas Christian University, where she graduated
          with a Bachelor’s in 2014. Jessica is a trained specialist in Facebook
          ad campaigns, content creation, and account management. As Jared's
          partner she travels the nation doing one on one consulting and
          strategic implementation for small businesses and corporations.
        </p>
      </>
    ),
  },
  {
    id: 7,
    title:
      'Session: How to Maximize Efficiency and Revenue with Shopmonkey Integrated Payments',
    time: '02:15 PM',
    body: (
      <>
        <p>
          Taking payment is a necessary step for every business. What if you
          could optimize that process? Instead of just accepting credit cards,
          you can have a record of all services and products purchased by each
          client. Hear from one Shopmonkey customer who is optimizing their
          business with Shopmonkey Payments.
        </p>
        <p className="sub-title">In this session you will learn:</p>
        <ul className="bullet-ul">
          <li>How you can get paid faster with remote payments</li>
          <li>
            How to eliminate manual entry errors and lost hours spent on
            accounting
          </li>
        </ul>
        <p className="sub-title">Speaker:</p>
        <p className="mb-2">
          <strong>Crissy Watkins</strong>
          <span className="d-block">Senior Payments Manager, Shopmonkey</span>
        </p>
        <p>
          Crissy has dedicated her career to helping clients optimize their
          business with integrated payments. She has built her career in a
          variety of roles, where she was not just an account manager but also a
          sales representative, project planner, escalation expert, and position
          architect. Born into a sales family she was always reaching for the
          win win! Taking the basic need for credit card processing and adding
          layers of value for small businesses helped her achieve just that.
        </p>
      </>
    ),
  },
  {
    id: 8,
    title: 'Break - Ice Cream Social & Beer; Complimentary Shop Assessments',
    time: '02:45 PM',
  },
  {
    id: 9,
    title: 'Session: Supercharging Your Operational Efficiency',
    time: '03:30 PM',
    body: (
      <>
        <p className="sub-title">What’s Possible: Case Study of Area Client</p>
        <ul className="bullet-ul">
          <li>Where they started, where are they at today (24 months)</li>
          <li>
            Secret to work/life balance = gathering and developing a great team
          </li>
          <li>How KPIs impact being able to gather and keep a great team</li>
        </ul>
        <p className="sub-title">Finding Efficiency Robbers and Fixing Them</p>
        <ul className="bullet-ul">
          <li>Productivity vs. efficiency</li>
          <li>ARO, Labor hours per ticket and DVIs – How they interact</li>
          <li>Getting buy-in from your team</li>
        </ul>
        <p className="sub-title">Speaker:</p>
        <p className="mb-2">
          <strong>Charlene L. Parlett,</strong> AMAM,
          <span className="d-block">
            CEC Executive Coach, Automotive Training Institute
          </span>
        </p>
        <p>
          Charlene Parlett, AMAM, grew up in her father’s motorcycle shop taking
          engines apart. In 2005 she and her business partner started an auto
          repair shop and after 12 years of profitable success, Charlene sold
          her share in the business to dedicate her time to helping other shop
          owners experience the freedom (and profits) your business should give
          you. Charlene is an Accredited Master of Automotive Management with
          AMi and a certified Executive Coach. As she tells her clients,
          “Knowledge isn’t power. The application of knowledge is power. Take
          what you learn and apply it. Success will come from that effort.”
        </p>
      </>
    ),
  },
  {
    id: 10,
    title: 'Closing Remarks',
    time: '04:15 PM',
  },
];
